<!-- downloadIssueReport -->
<template>
  <div class="downloadIssueReport" v-loading="loading">
    <div class="year-issue">
      <div class="choose-year">
        <div>选择内容类型：</div>
        <el-select
          v-model="currentType"
          placeholder="请选择"
          @change="typeChange"
        >
          <el-option :key="1" label="整期/专题" :value="1"> </el-option>
          <el-option :key="2" label="单篇" :value="2"> </el-option>
        </el-select>
      </div>
      <div class="choose-year ml-20" v-if="currentType === 1">
        <div>选择年份：</div>
        <el-select
          v-model="currentYear"
          placeholder="请选择"
          @change="yearChange"
        >
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="choose-issue ml-20" v-if="currentType === 1">
        <div>选择刊期：</div>
        <el-select
          v-model="currentIssue"
          placeholder="请选择"
          @change="issueChange"
        >
          <el-option
            v-for="item in issueOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="choose-year ml-20" v-if="currentType === 2">
        <div>选择年份：</div>
        <el-select
          v-model="currentYearSingle"
          placeholder="请选择"
          @change="yearSingleChange"
        >
          <el-option
            v-for="item in yearOptionsSingle"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="choose-year ml-20" v-if="currentType === 2">
        <div>选择标题：</div>
        <el-select
          v-model="currentTopic"
          placeholder="请选择"
          @change="topicChange"
        >
          <el-option
            v-for="item in topicList"
            :key="item.id"
            :label="item.topic"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <analysisReport
        ref="analysisReport"
        v-if="currentType === 1"
        @loadingChange="loadingChange"
      ></analysisReport>
      <analysisSingleReport
        v-else
        ref="analysisSingleReport"
        @loadingChange="loadingChange"
      ></analysisSingleReport>
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.communicationEffectStatistics
import { mapState } from 'vuex'
import analysisReport from '@comp/report/analysisReport'
import analysisSingleReport from '@comp/report/analysisSingleReport'
export default {
  name: 'DownloadIssueReport',
  data() {
    return {
      currentYear: '',
      currentIssue: '',
      yearOptions: [],
      issueOptions: [],
      list: [],
      taskId: '',
      networkId: '',
      type: '',
      currentType: 1,
      currentYearSingle: '',
      topicList: [],
      currentTopic: '',
      loading: true
    }
  },
  props: {},
  components: { analysisReport, analysisSingleReport },
  computed: {
    ...mapState('incrementService', ['magId'])
  },
  mounted() {
    if (this.magId) {
      this.getData()
    }
  },
  watch: {
    magId() {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.getList()
      var arrYear = []
      var myDate = new Date()
      var thisYear = myDate.getFullYear()
      var section = thisYear - 2022
      for (var i = 0; i <= section; i++) {
        arrYear.push(thisYear--)
      }
      this.yearOptionsSingle = arrYear
      this.currentYearSingle = this.yearOptionsSingle[0]
    },
    getList() {
      if (!this.magId) return
      const params = {
        page: 0,
        size: 999,
        magId: this.magId
      }
      let url = api.GetTaskList.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let yearArr = []
          this.yearOptions = []
          this.list = data.content
          if (data && data.content.length > 0) {
            data.content.map((item) => {
              if (
                yearArr.indexOf(item.year) === -1 &&
                item.year &&
                item.year > 2020
              ) {
                yearArr.push(item.year)
              }
            })
          }
          if (yearArr.length > 0) {
            yearArr.map((item) => {
              let obj = {
                value: item,
                label: item + '年'
              }
              this.yearOptions.push(obj)
            })
            this.currentYear = yearArr[0]
            this.yearChange(this.currentYear)
          } else {
            this.loading = false
          }
        }
      })
    },
    yearChange(val) {
      this.issueOptions = []
      this.list.map((item) => {
        if (item.year == val) {
          let obj = {
            value: item.issue,
            label: '第' + item.issue + '期',
            taskId: item.historyId,
            networkId: item.networkId,
            type: item.type
          }
          this.issueOptions.push(obj)
        }
      })
      this.currentIssue = this.issueOptions[0].value
      this.issueChange(this.currentIssue)
    },
    issueChange(val) {
      this.issueOptions.map((item) => {
        if (item.value == val) {
          this.taskId = item.taskId
          this.networkId = item.networkId
          this.type = item.type
        }
      })
      this.$nextTick(() => {
        this.$refs.analysisReport.getData(
          this.taskId,
          this.networkId,
          this.type,
          this.currentYear,
          this.currentIssue
        )
      })
    },
    typeChange() {
      if (this.currentType == 1) {
        this.getList()
      }
      if (this.currentType == 2) {
        this.getSingleList()
      }
    },
    getSingleList() {
      const params = {
        magId: this.magId,
        // isPortal: 1,
        status: 3,
        startPushTime: this.currentYearSingle + '-01-01 00:00:00',
        size: 999,
        type: 4
      }
      let url = '/das-api/vas/push/task/pushTaskList'
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (data && data.content) {
            this.topicList = data.content
            this.currentTopic = this.topicList[0].id
            this.$nextTick(() => {
              this.$refs.analysisSingleReport.getData(
                this.topicList[0],
                this.currentYearSingle
              )
            })
          }
        }
      })
    },
    yearSingleChange() {
      this.getSingleList()
    },
    topicChange() {
      let obj = {}
      this.topicList.map((item) => {
        if (item.id === this.currentTopic) {
          obj = item
        }
      })
      this.$refs.analysisSingleReport.getData(obj, this.currentYearSingle)
    },
    loadingChange(status) {
      this.loading = status
    }
  }
}
</script>

<style lang="scss" scoped>
.downloadIssueReport {
  padding: 10px;
  position: relative;
  width: 1135px;
  margin: auto;
  .year-issue,
  .choose-year,
  .choose-issue {
    display: flex;
    align-items: center;
  }
  .year-issue {
    margin-bottom: 20px;
  }
}
</style>
